<template>
  <base-layout>
    <BillingPlan
      :pageTitle="$t('components.billingPlanManagement.privateHeadline')"
      :pricingPlanType="config.type.private"
    />
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import BillingPlan from '@/views/pricing/billing-plan/index.vue'
import { BillingPlanConfig } from '@/config/BillingPlanConfig'
export default {
  name: 'BillingPlans',

  components: {
    BaseLayout,
    BillingPlan,
  },
  data() {
    return {
      config: BillingPlanConfig,
    }
  },
}
</script>
